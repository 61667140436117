import React, { useEffect, useState } from "react"
import Logo from "./Logo"
import Button from "./Button"
import Menu from "./Menu"
import MobileMenu from "./MobileMenu"

import "./Header.css"
import Banner from "./Banner"

function Header() {
  const [isShowMobileMenu, setIsShowMobileMenu] = useState(false)
  const [isHeaderHidden, setIsHeaderHidden] = useState(false)

  const menuItems = [
    {
      label: "Pesquisa de Passagens",
      action: () => setIsShowMobileMenu(false),
    },
    {
      label: "Passagens Aéreas Baratas",
      action: async () => {
        setIsShowMobileMenu(false)
        window.location.href =
          "https://voopter.com.br/passagens-aereas-promocionais"
      },
    },
    {
      label: "Alertas",
      action: () => (window.location.href = "https://voopter.com.br/alertas"),
    },
    {
      label: "Hotéis",
      action: () => window.open("https://voopter.club/trivago", "_blank"),
    },
  ]

  useEffect(() => {
    const handleScroll = () => {
      setIsHeaderHidden(window.scrollY >= 95)
    }
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  return (
    <header className={`${isHeaderHidden ? "hidden" : "show"}`}>
      <MobileMenu
        isVisible={isShowMobileMenu}
        onClose={() => setIsShowMobileMenu(false)}
        menuItems={menuItems}
      />

      <Button onClick={() => setIsShowMobileMenu(true)} />

      <div className="header-content">
        <Logo />

        <div className="div-options">
          <Menu items={menuItems} />

          <Banner />
        </div>
      </div>
    </header>
  )
}

export default Header
